











































import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue';
import DialogoDeEdicaoDeAcaoDeCashback from '@/components/cashback/DialogoDeEdicaoDeAcaoDeCashback.vue';
import { AcaoDeCashback } from '@/models/AcaoDeCashback';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore';
import  { CancelToken } from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { FindCashbackUseCase } from '@/usecases';
import { dateToPtBrFormat } from "@/shareds/date/date-utils"
import { Pageable, Page } from '@/models';


@Component({
	components: {
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeAcaoDeCashback,
	},
})

export default class TelaDeAcaoDeCashback extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicaoDeAcaoDeCashback!: DialogoDeEdicaoDeAcaoDeCashback

	busca = ''
	carregando = false
	editando = false
	acaoDeCashback: AcaoDeCashback | null = null
	acoesDeCashback: AcaoDeCashback[] = [];
	findUseCase = new FindCashbackUseCase()

	headers = [
		{ text: 'Acão', value: 'nomeAcao', sortable: false },
		{ text: 'Descrição', value: 'descricao', sortable: false},
		{ text: 'Data Inicio', value: 'dataInicio', sortable: false},
		{ text: 'Data Fim', value: 'dataFim', sortable: false},
	]

	async buscar(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<AcaoDeCashback>> {
		const page = await this.findUseCase.findAcoesDeCashback({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.acoesDeCashback = page.content
		return page
	}


	abrirDialogoDeEditarAcaoDeCashback(indice: number) {
		try {
			if(!this.temRecurso('alterar', "acao-de-cashback")) {
				throw new Error('Necessário permissão para editar')
			}
			this.editando = true
			this.acaoDeCashback = this.acoesDeCashback[indice]

			this.dialogoDeEdicaoDeAcaoDeCashback.mostrar(this.acaoDeCashback, this.editando)
			this.dialogoDeEdicaoDeAcaoDeCashback.$on('sucesso', this.buscar)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	abrirDialogoDeCriarAcaoDeCashback() {
		try {
			if(!this.temRecurso('criar', 'acao-de-cashback')) 
				throw new Error('Necessário permissão para criar')
			this.editando = false
			this.acaoDeCashback = {
				id: '',
				grupoEconomico: null,
				nomeAcao: '',
				descricao: '',
				dataInicio: '',
				dataFim: '',
			} as AcaoDeCashback

			this.dialogoDeEdicaoDeAcaoDeCashback.mostrar(this.acaoDeCashback, this.editando)
			this.dialogoDeEdicaoDeAcaoDeCashback.$on('sucesso', this.buscar)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	get temRecurso() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}

	get itensFormatados() {
		return this.acoesDeCashback.map(acao => ({
			...acao,
			dataInicio: acao.dataInicio != null ? dateToPtBrFormat(acao.dataInicio) : '- -',
			dataFim: acao.dataFim != null ? dateToPtBrFormat(acao.dataFim) : '- -',
		}),
		)
	}
}
